import {
 defineStore,
 storeToRefs,
} from 'pinia';
import { API_PREFIX } from '~/constants/apiConfiguration';
import { useAuthStore } from '~/stores/auth';
import type { TournamentsStoreState } from '~/types/stores/tournaments';
import type {
 FetchTournamentByGame,
 FetchTournamentsActual,
 FetchTournament,
 FetchTournamentsFinished,
 FetchTournamentsFinishedForSSR,
 TournamentItem,
 TournamentsActualParams,
 TournamentsFinishedParams,
} from '~/types/general/tournament';

export const useTournamentsStore = defineStore({
  id: 'tournaments',
  state: (): TournamentsStoreState => ({
    tournamentByGame: null,
    tournamentById: null,
    tournamentsActual: [],
    tournamentsFinished: {
      total: 0,
      items: [],
    },
  }),
  getters: {
    getTournamentByGame: (state) => state.tournamentByGame,
    getTournamentById: (state) => state.tournamentById,
    getTournamentsActual: (state) => state.tournamentsActual,
    getTournamentsFinished: (state) => state.tournamentsFinished,
  },
  actions: {
    async fetchTournamentByGame (gameCode: string, queries: {[key: string]: string}) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const { data } = await useMyFetch().get(`${API_PREFIX.tournaments}/game/${gameCode}`, {
          query: {
            lang: getUserData.value.locale,
            playerId: getUserData.value.playerId,
            gamePlatform: queries.platform,
            gameType: queries.gameType,
          },
        }) as FetchTournamentByGame;

        this.tournamentByGame = data.tournament;
      } catch (error) {
        throw(error);
      }
    },
    async fetchTournamentById (id: number, gamePlatform: string) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const { data } = await useMyFetch().get(`${API_PREFIX.tournaments}/${id}`, {
          query: {
            lang: getUserData.value.locale,
            playerId: getUserData.value.playerId,
            gamePlatform,
          },
        }) as FetchTournament;

        this.tournamentById = data;
      } catch (error) {
        throw(error);
      }
    },
    async joinTournament (tournamentId: number) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        await useMyFetch().post('/joinTournament.do', {
          query: {
            playerId: getUserData.value.playerId,
            tournamentId,
          },
        });
      } catch (error) {
        throw(error);
      }
    },
    async fetchTournamentsActual (params: TournamentsActualParams) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const { data } = await useMyFetch().get(`${API_PREFIX.tournamentsActual}`, {
          query: {
            ...params,
            lang: getUserData.value.locale,
            playerId: getUserData.value.playerId,
          },
        }) as FetchTournamentsActual;

        this.tournamentsActual = data;
      } catch (error) {
        throw(error);
      }
    },
    async fetchTournamentsActualForSSR (params: TournamentsActualParams) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);
      const url = `${useBaseAppUrl().api}${API_PREFIX.tournamentsActual}`;
      const startTime = Date.now();
      const { $ssrRequestLogger } = useNuxtApp();

      try {
        this.tournamentsActual = await $fetch(url, {
          query: {
            ...params,
            lang: getUserData.value.locale,
            playerId: getUserData.value.playerId,
          },
        }) as TournamentItem[];

        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: true,
            result: this.tournamentsActual,
          });
        }
      } catch (error) {
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: false,
            result: error,
          });
        }
      }
    },
    async fetchTournamentsFinished (params: TournamentsFinishedParams) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const { data } = await useMyFetch().get(API_PREFIX.tournaments, {
          query: {
            ...params,
            lang: getUserData.value.locale,
            playerId: getUserData.value.playerId,
          },
        }) as FetchTournamentsFinished;

        params.page > 1
          ? this.tournamentsFinished.items = [...this.tournamentsFinished.items, ...data.items]
          : this.tournamentsFinished = data;
      } catch (error) {
        throw(error);
      }
    },
    async fetchTournamentsFinishedForSSR (params: TournamentsFinishedParams) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);
      const url = `${useBaseAppUrl().api}${API_PREFIX.tournaments}`;
      const startTime = Date.now();
      const { $ssrRequestLogger } = useNuxtApp();

      try {
        this.tournamentsFinished = await $fetch(url, {
          query: {
            ...params,
            lang: getUserData.value.locale,
            playerId: getUserData.value.playerId,
          },
        }) as FetchTournamentsFinishedForSSR;
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: true,
            result: this.tournamentsFinished,
          });
        }
      } catch (error) {
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: false,
            result: error,
          });
        }
      }
    },
  },
});
